import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Outer } from '../templates/post';
import Container from '../components/Container';
import FadeEntry from '../components/FadeEntry';
import MetaData from '../components/MetaData';
import Page from '../components/Page';
import Title from '../components/Title';
// import { Hero } from '../components/Blocks';
// import { MetaData } from '../components/Misc';

const NotFound = () => {

	let { wordpressAcfOptions: {
		options: {
			wordpress_404_title: title,
			wordpress_404_text: text,
		}
	} } = useStaticQuery(graphql`
		{
			wordpressAcfOptions {
				options {
					wordpress_404_title
					wordpress_404_text
				}
			}
		}
	`);

	return(
		<Outer>
			<MetaData
				title={title}
			/>

			<Page>
				<Container
					size={'narrow'}
				>
					<FadeEntry>
						<Title
							size={'titleLg'}
							style={{
								marginBottom: '40px'
							}}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
					</FadeEntry>

					<div
						className="user-content"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</Container>
			</Page>
		</Outer>
	);
}

export default NotFound;